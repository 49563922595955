import React from 'react';
import PropertyDetails from '../PropertyDetails';
import Price from 'components/cards/new-layout/components/Price';
import { SpacingBlock } from 'components/spacing-block';
import AgentTags from 'components/cards/new-layout/components/AgentTags';
import { useLocale } from 'locale';
import { getBuildingClassKey } from 'utils/entities';
import { useUniversalCardAddress } from 'components/cards/listing/UniversalCard/utils';
import { CardInnerSeparator, PropertyPrimaryLine, PropertyPrimaryInfo } from '../styled';
import { UniversalCardExtraProps } from 'components/cards/new-layout/types';
import { BulletinUniversalCardProps } from 'components/cards/types';

const BulletinCard: React.FC<BulletinUniversalCardProps & UniversalCardExtraProps> = (props) => {
  const {
    isAgent,
    isExclusive,
    madadSearchResult,
    price,
    eventsHistory,
    bedsCount,
    size,
    floor,
    buildingClass,
    streetName,
    streetNumber,
    neighbourhood,
    unitNumber,
    city,
    resolutionPreferences,
    isSearchInWholeCountry,
  } = props;

  const localeProps = useLocale();
  const { t, formatArea } = localeProps;

  const propertyTypeLabel = !!buildingClass ? (
    t('unitPage.buildingClass', { buildingClass: getBuildingClassKey(buildingClass), isCommercial: false })
  ) : null;

  const primaryAddress = useUniversalCardAddress(
    streetName,
    streetNumber,
    neighbourhood,
    unitNumber,
    city,
    resolutionPreferences,
    isSearchInWholeCountry
  );

  const secondaryLine = [ propertyTypeLabel, primaryAddress ].filter(Boolean).join(', ');


  return (
    <>
      <PropertyPrimaryInfo>
        <PropertyPrimaryLine data-auto="property-address">
          {secondaryLine}
        </PropertyPrimaryLine>
        {isAgent ? (
          <SpacingBlock mTop={0.5}>
            <AgentTags isExclusive={isExclusive} madadSearchResult={madadSearchResult} />
          </SpacingBlock>
        ) : null}
      </PropertyPrimaryInfo>
      <CardInnerSeparator />
      <PropertyDetails
        rooms={bedsCount}
        floor={floor}
        area={formatArea(size, false)}
      />
      <CardInnerSeparator />
      <Price price={price} eventsHistory={eventsHistory} direction="up" />
    </>
  );
};

export default BulletinCard;
