import React from 'react';
import FavouritesToggle from 'components/cards/new-layout/components/FavouritesCardButton';
import BulletinInfo from './property-info/BulletinInfo';
import ProjectInfo from './property-info/ProjectInfo';
import CommercialBulletinInfo from './property-info/CommercialBulletinInfo';
import CommercialProjectInfo from './property-info/CommercialProjectInfo';
import { ListViewCardProps } from '../';
import * as Styled from './styled';
import { CardType } from 'components/cards/types';


const ListViewCardLarge: React.FC<ListViewCardProps> = (props) => {
  let propertyInfo = null;
  switch (props.cardType) {
    case CardType.Bulletin:
      propertyInfo = <BulletinInfo {...props} />;
      break;
    case CardType.Project:
      propertyInfo = <ProjectInfo {...props} />;
      break;
    case CardType.CommercialBulletin:
      propertyInfo = <CommercialBulletinInfo {...props} />;
      break;
    case CardType.CommercialProject:
      propertyInfo = <CommercialProjectInfo {...props} />;
      break;
  }

  return (
    <Styled.CardInner>
      <Styled.SaveToListWrapper>
        <FavouritesToggle id={props.id} type={props.type} />
      </Styled.SaveToListWrapper>
      <Styled.GalleryWrapper>
        {props.imageWithTags}
      </Styled.GalleryWrapper>
      {propertyInfo}
    </Styled.CardInner>
  );
};

export default ListViewCardLarge;
