import styled from '@emotion/styled';

export const CardWrapper = styled.div`
  position: relative;
`;

export const CardInner = styled.div`
  ${({ theme }) => `
    display: flex;
    gap: ${theme.spacing(1)};
  `}
`;

export const PropertyInfoText = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: end;
`;

export const PropertyDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SaveToListWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
`;
