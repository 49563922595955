import React from 'react';
import Tag, { TagType } from '../components/Tag';
import { useABTests } from 'hooks/useABTests';
import { AddLastUpdateToWeights } from 'config/abTests';
import { daysFrom } from 'helpers/dateUtils';
import { UnitRelevancy, getRelevancy } from 'components/unit-page/RelevancyLabel';
import { createSelector } from 'reselect';
import { lastViewedPoiIdsSet } from 'store/state/domainData/selectors';
import { State } from 'store/state';
import { PoiId, DealType } from 'utils/entities';
import { connect } from 'react-redux';
import config from 'config';
import { ISOString } from 'locale/formatDate';

interface ListingTagsProps {
  hasPriceDrop: boolean;
  lastUpdatedDate: ISOString;
  createdDate: ISOString;
  isInLastViewed?: boolean;
  dealType: DealType;
  id: PoiId;
}

const ListingTags: React.FC<ListingTagsProps> = (props) => {
  const { hasPriceDrop, lastUpdatedDate, createdDate, isInLastViewed, dealType } = props;
  const relevancy = getRelevancy(createdDate, isInLastViewed, true, dealType);
  const { _be_addLastUpdateToWeights } = useABTests();
  const isModeB = _be_addLastUpdateToWeights === AddLastUpdateToWeights.modeB;
  const isUpdated = new Date(lastUpdatedDate).getTime() !== new Date(createdDate).getTime();
  const passedUpdateDays = lastUpdatedDate ? daysFrom(new Date(lastUpdatedDate)) : null;


  const daysInMarket = config.daysInMarket[dealType];
  if (!daysInMarket) return null;

  if (hasPriceDrop) {
    return <Tag data-auto="date-label" type={TagType.PriceDrop} />;
  }
  else if (isModeB && isUpdated && passedUpdateDays === 0) {
    return <Tag data-auto="date-label" type={TagType.UpdatedToday} />;
  }
  else if (relevancy === UnitRelevancy.New) {
    return <Tag data-auto="date-label" type={TagType.New} />;
  }

  return null;
};

const isInLastViewedSelector = createSelector([
  lastViewedPoiIdsSet,
  (_: State, id: PoiId) => id,
], (set, id) => set.has(id));

const mapStateToProps = (state: State, props: ListingTagsProps) => ({
  isInLastViewed: isInLastViewedSelector(state, props.id),
});

export default connect(mapStateToProps)(ListingTags);
