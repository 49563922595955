import React, { useMemo } from 'react';
import {
  PropertyPrimaryLine,
  PropertySecondaryLine,
  ProjectNameLabel,
} from 'components/cards/new-layout/styled';
import { useLocale } from 'locale';
import renderLineItem from 'components/labelLineItem';
import { getProjectInfoLineConfig } from 'components/cards/new-layout/components/info-line-config';
import { SpacingBlock } from 'components/spacing-block';
import * as Styled from '../styled';
import { ProjectUniversalCardProps } from 'components/cards/types';

const ProjectCard: React.FC<ProjectUniversalCardProps> = (props) => {
  const {
    isPromoted,
    projectName,
    bedsRange,
    priceRange,
    floorRange,
  } = props;

  const localeProps = useLocale();
  const { t } = localeProps;

  const infoLineConfig = useMemo(() => {
    return getProjectInfoLineConfig(bedsRange, isPromoted, priceRange, floorRange, localeProps).filter(({ showCondition }) => showCondition);
  }, [ bedsRange, isPromoted, priceRange, floorRange, localeProps, getProjectInfoLineConfig ]);

  const name = projectName ? projectName : t('universalCard.projectLabel.defaultName');

  return (
    <>
      <SpacingBlock mLeft={5}>
        <ProjectNameLabel size="small" isPromoted={isPromoted}>
          {name}
        </ProjectNameLabel>
      </SpacingBlock>
      <SpacingBlock mTop={0.5}>
        <PropertySecondaryLine data-auto="property-address">
          {t('universalCard.project.address', {
            city: props.city || null,
            streetName: props.streetName || null,
            streetNumber: props.streetNumber || null,
          })}
        </PropertySecondaryLine>
      </SpacingBlock>
      <Styled.PropertyInfoText>
        <PropertyPrimaryLine>
          {infoLineConfig.map(renderLineItem)}
        </PropertyPrimaryLine>
      </Styled.PropertyInfoText>
    </>
  );
};

export default ProjectCard;
