import React from 'react';
import { H3 } from 'ds/components/typography';
import styled from '@emotion/styled';
import { useLocale } from 'locale';
import { PropertySecondaryLine, PropertyInfoEmptySpace } from './styled';

interface PropertyDetailsProps {
  area?: number | string;
  rooms?: number | string;
  floor?: number | string;
  hideArea?: boolean;
  hideRooms?: boolean;
  isRange?: boolean;
  maxColumns?: 2 | 3;
}

export const PropertyDetailsRoot = styled(PropertyInfoEmptySpace)<{ noMargin?: boolean; }>`
  display: flex;
  text-align: center;
`;

const PropertyDetailsItem = styled.div<{ maxColumns: 2 | 3}>`
  ${({ maxColumns }) => `
    flex-basis: calc(100% / ${maxColumns});
  `}
`;
const PropertyDetailsLabel = styled(PropertySecondaryLine)``;
const PropertyDetailsValue = styled(H3)`
  ${({ theme }) => `
    color: ${theme.colors.neutrals.grey1};
    margin-bottom: ${theme.spacing(0.5)};
  `}
`;

const EMPTY_VALUE = '-';

const PropertyDetails: React.FC<PropertyDetailsProps> = (props) => {
  const { area, rooms, floor, hideArea, hideRooms, isRange, maxColumns = 3 } = props;
  const { t } = useLocale();
  const floorValue = t('universalCard.floorValue', { floor });
  const areaValue = area && area !== '0' ? area : EMPTY_VALUE;

  return (
    <PropertyDetailsRoot withInnerMargin={maxColumns !== 2}>
      {hideRooms ? null : (
        <PropertyDetailsItem maxColumns={maxColumns}>
          <PropertyDetailsValue data-auto="property-rooms" weight="bold">{rooms || EMPTY_VALUE}</PropertyDetailsValue>
          <PropertyDetailsLabel>{t('universalCard.rooms')}</PropertyDetailsLabel>
        </PropertyDetailsItem>
      )}
      <PropertyDetailsItem maxColumns={maxColumns}>
        <PropertyDetailsValue data-auto="property-floor" weight="bold">{floorValue}</PropertyDetailsValue>
        <PropertyDetailsLabel>
          {isRange ? t('universalCard.floors') : t('universalCard.floor')}
        </PropertyDetailsLabel>
      </PropertyDetailsItem>
      {hideArea ? null : (
        <PropertyDetailsItem maxColumns={maxColumns}>
          <PropertyDetailsValue data-auto="property-value" weight="bold">{areaValue}</PropertyDetailsValue>
          <PropertyDetailsLabel>{t('universalCard.area')}</PropertyDetailsLabel>
        </PropertyDetailsItem>
      )}
    </PropertyDetailsRoot>
  );
};

export default PropertyDetails;
