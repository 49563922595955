import React from 'react';
import { useLocale } from 'locale';
import { ProjectNameLabel } from 'components/cards/new-layout/styled';
import {
  CardInnerSeparator,
  PropertyPrimaryInfo,
  PropertyPrimaryLine,
  PropertyInfoEmptySpace,
} from '../styled';
import { SpacingBlock } from 'components/spacing-block';
import Price from 'components/cards/new-layout/components/Price';
import PropertyDetails from 'components/cards/new-layout/list-view-card/card-large/PropertyDetails';
import { getBedsRangeParams } from 'utils/addressFormatter';
import { ProjectUniversalCardProps } from 'components/cards/types';


const ProjectCard: React.FC<ProjectUniversalCardProps> = (props) => {
  const {
    isPromoted,
    projectName,
    priceRange,
    bedsRange,
    floorRange,
  } = props;
  const { t } = useLocale();
  const name = projectName ? projectName : t('universalCard.projectLabel.defaultName');
  const showPrice = Boolean(priceRange && priceRange.min);
  const bedsMinMax = getBedsRangeParams(bedsRange.min, bedsRange.max);
  const showBeds = bedsRange && typeof bedsRange.min === 'number' && typeof bedsRange.max === 'number';
  const showFloor = floorRange && typeof floorRange.max === 'number';

  return (
    <>
      <PropertyPrimaryInfo>
        <ProjectNameLabel size="small" isPromoted={isPromoted}>
          {name}
        </ProjectNameLabel>
        <SpacingBlock mTop={0.5}>
          <PropertyPrimaryLine data-auto="property-address">
            {t('universalCard.project.address', {
              city: props.city || null,
              streetName: props.streetName || null,
              streetNumber: props.streetNumber || null,
            })}
          </PropertyPrimaryLine>
        </SpacingBlock>
      </PropertyPrimaryInfo>
      <CardInnerSeparator hide={!showBeds && !showFloor} />
      {showBeds || showFloor ? (
        <PropertyDetails
          rooms={t('universalCard.project.bedsRangeLabel', { ...bedsMinMax })}
          floor={floorRange.max}
          hideArea
          isRange
        />
      ) : <PropertyInfoEmptySpace />}
      {showPrice ? (
        <>
          <CardInnerSeparator hide={!showBeds && !showFloor}/>
          <div>
            <PropertyPrimaryLine>{t('universalCard.priceFrom')}</PropertyPrimaryLine>
            <Price price={priceRange.min} />
          </div>
        </>
      ) : null}
    </>
  );
};

export default ProjectCard;
