import React from 'react';
import styled from '@emotion/styled';
import FavoritesToggler from 'components/favorites/FavoritesTogglerNew';
import { PoiId, PoiType } from 'utils/entities';

export const FavouritesWrapper = styled.div`
  ${({ theme }) => `
    > div {
      border: 1px solid ${theme.colors.neutrals.grey8};
    }
  `}
`;

const FavouritesCardButton: React.FC<{ id: PoiId, type: PoiType; size?: 'medium' | 'large'; }> = (props) => {
  const { id, type, size = 'medium' } = props;
  const toggleFavorite = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };
  return (
    <FavouritesWrapper onClick={toggleFavorite}>
      <FavoritesToggler
        data-auto="bulletin-list-card-favorite-toggler"
        id={id}
        type={type}
        size={size}
      />
    </FavouritesWrapper>
  );
};

export default FavouritesCardButton;
