import React from 'react';
import { TransformedImage } from 'components/transformed-image';
import { CheckedLink, getDeveloperLink, getAgencyLink } from 'utils/checkLinksWrapper';
import styled from '@emotion/styled';
import { CardType } from 'components/cards/types';

const ThumbnailLogo = styled.div<{ size: number; }>`
  ${({ theme, size }) => `
    display: flex;
    border: 1px solid ${theme.colors.neutrals.grey8};
    border-radius: ${theme.borderRadius.tiny};
    background-color: ${theme.colors.neutrals.white};
    padding: 2px;
    height: ${size}px;
    width: ${size}px;
  `}

  img {
    display: block;
    object-fit: contain;
    max-width: 100%;
    height: 100%;
    margin: 0 auto;
  }
`;

interface LogoLinkProps {
  logoPath: string;
  cardType: CardType;
  id?: string;
  size: number;
}

const LogoLink: React.FC<LogoLinkProps> = ({ cardType, logoPath, id, size }) => {
  const isProject = cardType === CardType.Project || cardType === CardType.CommercialProject;
  const link = cardType === CardType.Project || cardType === CardType.CommercialProject
    ? getDeveloperLink(id)
    : getAgencyLink(id);

  if (!link) return null;

  return (
    <CheckedLink
      link={link}
      isPreventEvent
    >
      <ThumbnailLogo className="thumbnail-logo" size={size}>
        <TransformedImage
          path={logoPath}
          height={size}
          dataAuto={isProject ? 'developer-logo-image' : 'office-logo-image'}
        />
      </ThumbnailLogo>
    </CheckedLink>
  );
};

export default LogoLink;
