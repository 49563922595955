import styled from '@emotion/styled';
import { Text } from 'ds/components/typography';

export const CardInner = styled.div`
  display: flex;
  position: relative;
  align-items: center;
`;

export const SaveToListWrapper = styled.div`
  position: absolute;
  left: 4px;
  top: 4px;
`;

export const CardInnerSeparator = styled.div<{ hide?: boolean; }>`
  ${({ theme, hide }) => `
    height: 52px;
    width: 1px;
    margin: 0 ${theme.spacing(3)};
    ${hide ? '' : `background-color: ${theme.colors.neutrals.grey8};`}
  `}
`;

export const GalleryWrapper = styled.div`
  ${({ theme }) => `
    margin-left: ${theme.spacing(3)};
  `}
`;

export const PropertyPrimaryLine = styled(Text)`
  ${({ theme }) => `
    color: ${theme.colors.neutrals.grey1};
  `}
`;

export const PropertySecondaryLine = styled(Text)`
  ${({ theme }) => `
    color: ${theme.colors.neutrals.grey2};
  `}
`;

export const PropertyPrimaryInfo = styled.div`
  width: 211px;
  min-width: 211px;
`;

const INFO_WIDTH = '164px';
const INFO_MARGIN = '20px';

export const PropertyInfoEmptySpace = styled.div<{ withInnerMargin?: boolean; }>`
  ${({ withInnerMargin }) => `
    width: calc(${INFO_WIDTH} ${withInnerMargin ? `+ ${INFO_MARGIN} * 2` : ''});
    min-width: calc(${INFO_WIDTH} ${withInnerMargin ? `+ ${INFO_MARGIN} * 2` : ''});
    margin: 0 ${withInnerMargin ? `-${INFO_MARGIN}` : ''};
  `}
`;
