import React from 'react';
import { UniversalCardExtraProps } from 'components/cards/new-layout/types';
import ListViewCard from './card';
import ListViewCardLarge from './card-large';
import styled from '@emotion/styled';
import CardImageWithTags from './CardImageWithTags';
import { UniversalCardPoiUnion, CardType } from 'components/cards/types';


export const Root = styled.div<{ small?: boolean; }>`
  ${({ theme, small }) => `
    padding: ${small ? theme.spacing(1) : theme.spacing(0.5)};
  `}
`;

export type ListUniversalCardProps = UniversalCardPoiUnion & UniversalCardExtraProps & { small: boolean; };
export type ListViewCardProps = UniversalCardPoiUnion & UniversalCardExtraProps;

const ListViewUniversalCard: React.FC<ListUniversalCardProps> = (props) => {
  const { small, ...rest } = props;
  const logoSourceId = props.cardType === CardType.Project || props.cardType === CardType.CommercialProject
    ? props.developerId
    : props.officeId;
  const isPromoted = (props.cardType === CardType.Project || props.cardType === CardType.CommercialProject) && props.isPromoted;
  const hasDiscount = (props.cardType === CardType.Project || props.cardType === CardType.CommercialProject) && props.hasDiscount;
  const eventsHistory = (props.cardType === CardType.Bulletin || props.cardType === CardType.CommercialBulletin) ? props.eventsHistory : [];
  const imageWithTags = (
    <CardImageWithTags
      logoPath={props.logoPath}
      cardType={props.cardType}
      thumbnails={props.thumbnails}
      small={small}
      isPromoted={isPromoted}
      hasDiscount={hasDiscount}
      id={props.id}
      eventsHistory={eventsHistory}
      dealType={props.dealType}
      lastUpdatedDate={props.lastUpdatedDate}
      date={props.date}
      logoSourceId={logoSourceId}
    />
  );

  return (
    <Root small={small}>
      {small
        ? <ListViewCard imageWithTags={imageWithTags} {...rest} />
        : <ListViewCardLarge imageWithTags={imageWithTags} {...rest} />}
    </Root>
  );
};

export default ListViewUniversalCard;
