import React from 'react';
import {
  PropertyPrimaryLine,
  PropertyPrimaryInfo,
  CardInnerSeparator,
  PropertyInfoEmptySpace,
} from 'components/cards/new-layout/list-view-card/card-large/styled';
import { ProjectNameLabel } from 'components/cards/new-layout/styled';
import { SpacingBlock } from 'components/spacing-block';
import { useLocale } from 'locale';
import PropertyDetails from 'components/cards/new-layout/list-view-card/card-large/PropertyDetails';
import Price from 'components/cards/new-layout/components/Price';
import { CommercialProjectUniversalCardProps } from 'components/cards/types';


const CommercialProjectCard: React.FC<CommercialProjectUniversalCardProps> = (props) => {
  const {
    isPromoted,
    projectName,
    floorRange,
    priceRange,
  } = props;
  const { t } = useLocale();
  const name = projectName ? projectName : t('universalCard.projectLabel.defaultName');
  const showFloor = floorRange && typeof floorRange.max === 'number';
  const showPrice = Boolean(priceRange && priceRange.min);

  return (
    <>
      <PropertyPrimaryInfo>
        <ProjectNameLabel size="small" isPromoted={isPromoted}>
          {name}
        </ProjectNameLabel>
        <SpacingBlock mTop={0.5}>
          <PropertyPrimaryLine data-auto="property-address">
            {t('universalCard.project.address', {
              city: props.city || null,
              streetName: props.streetName || null,
              streetNumber: props.streetNumber || null,
            })}
          </PropertyPrimaryLine>
        </SpacingBlock>
      </PropertyPrimaryInfo>
      <CardInnerSeparator hide={!showFloor} />
      {showFloor ? (
        <PropertyDetails
          hideRooms
          floor={floorRange.max}
          hideArea
          isRange
          maxColumns={2}
        />
      ) : <PropertyInfoEmptySpace />}
      {showPrice ? (
        <>
          <CardInnerSeparator hide={!showFloor} />
          <div>
            <PropertyPrimaryLine>{t('universalCard.priceFrom')}</PropertyPrimaryLine>
            <Price price={priceRange.min} />
          </div>
        </>
      ) : null}
    </>
  );
};

export default CommercialProjectCard;
