import React from 'react';
import { UniversalCardExtraProps } from 'components/cards/new-layout/types';
import {
  CardInnerSeparator,
  PropertyPrimaryInfo,
  PropertyPrimaryLine,
  PropertySecondaryLine,
} from '../styled';
import Price from 'components/cards/new-layout/components/Price';
import { TagsWrapper } from 'components/cards/new-layout/styled';
import AgentTags from 'components/cards/new-layout/components/AgentTags';
import Tag, { TagType } from 'components/cards/new-layout/components/Tag';
import { SpacingBlock } from 'components/spacing-block';
import { useLocale } from 'locale';
import { getBuildingClassKey } from 'utils/entities';
import { useUniversalCardAddress } from 'components/cards/listing/UniversalCard/utils';
import PropertyDetails from 'components/cards/new-layout/list-view-card/card-large/PropertyDetails';
import { CommercialBulletinUniversalCardProps } from 'components/cards/types';

const CommercialBulletin: React.FC<CommercialBulletinUniversalCardProps & UniversalCardExtraProps> = (props) => {
  const {
    price,
    isAgent,
    isExclusive,
    generalCondition,
    qualityClass,
    buildingClass,
    streetName,
    streetNumber,
    neighbourhood,
    unitNumber,
    city,
    resolutionPreferences,
    isSearchInWholeCountry,
    floor,
    size,
    area,
  } = props;

  const { t, formatMoney, formatArea } = useLocale();

  const primaryAddress = useUniversalCardAddress(
    streetName,
    streetNumber,
    neighbourhood,
    unitNumber,
    city,
    resolutionPreferences,
    isSearchInWholeCountry
  );
  const propertyTypeLabel = !!buildingClass ? (
    t('unitPage.buildingClass', { buildingClass: getBuildingClassKey(buildingClass), isCommercial: true })
  ) : null;
  const secondaryLine = [ propertyTypeLabel, primaryAddress ].filter(Boolean).join(', ');

  return (
    <>
      <PropertyPrimaryInfo>
        <PropertyPrimaryLine data-auto="property-address">
          {secondaryLine}
        </PropertyPrimaryLine>
        <SpacingBlock mTop={0.5}>
          <TagsWrapper>
            {isAgent ? <AgentTags isExclusive={isExclusive} /> : null}
            {generalCondition ? (
              <Tag
                type={TagType.Commercial}
                label={t('filters.conditionsOptions', { value: generalCondition })}
              />
            ) : null}
            {qualityClass ? (
              <Tag
                type={TagType.Commercial}
                label={t('commercial.listing.offerDetails.qualityClass', { value: qualityClass })}
              />
            ) : null}
          </TagsWrapper>
        </SpacingBlock>
      </PropertyPrimaryInfo>
      <CardInnerSeparator />
      <PropertyDetails
        hideRooms
        floor={floor}
        area={formatArea(size, false)}
        maxColumns={2}
      />
      {price ? (
        <>
          <CardInnerSeparator />
          <div>
            <Price price={price} />
            {area && price ? (
              <PropertySecondaryLine data-auto="property-ppm">
                {t('widget.prices.pricePerSqMeter', { price: formatMoney(price / area) })}
              </PropertySecondaryLine>
            ) : null}
          </div>
        </>
      ) : null}
    </>
  );
};

export default CommercialBulletin;
