import React from 'react';
import styled from '@emotion/styled';
import config from 'config';
import LogoLink from 'components/cards/new-layout/components/LogoLink';
import PropertyImage from 'components/cards/new-layout/components/PropertyImage';
import { IThumbnail, PriceEvent, PoiId, DealType } from 'utils/entities';
import ListingTags from 'components/cards/new-layout/components/ListingTags';
import ProjectTag from 'components/cards/new-layout/components/ProjectTag';
import { ISOString } from 'locale/formatDate';
import { isPriceDropped } from 'utils/priceEvents';
import { CardType } from 'components/cards/types';


const CardImageWithTagsWrapper = styled.div`
  position: relative;
`;

const ThumbnailLogoWrapper = styled.div<{ small: boolean; }>`
  ${({ small, theme }) => `
    background-color: ${theme.colors.neutrals.white};
    border-radius: ${theme.borderRadius.tiny};
    position: absolute;
    ${small ? `
      right: 8px;
      bottom: 8px;
    ` : `
      right: 4px;
      bottom: 4px;
    `}
  `}
`;

const TagsWrapper = styled.div<{ small: boolean; }>`
  ${({ small }) => `
    position: absolute;
    ${small ? `
      top: 8px;
      right: 8px;
    ` : `
      top: 4px;
      right: 4px;
    `}
  `}
`;

interface CardImageWithTagsProps {
  cardType: CardType;
  small: boolean;
  logoPath: string;
  thumbnails: IThumbnail[];
  logoSourceId: string;
  eventsHistory: PriceEvent[];
  hasDiscount: boolean;
  isPromoted: boolean;
  id: PoiId;
  date: ISOString;
  lastUpdatedDate: ISOString;
  dealType: DealType;
}


const CardImageWithTags: React.FC<CardImageWithTagsProps> = (props) => {
  const {
    logoPath,
    cardType,
    thumbnails,
    small,
    isPromoted,
    hasDiscount,
    id,
    eventsHistory,
    dealType,
    lastUpdatedDate,
    date,
    logoSourceId,
  } = props;
  const isProject = props.cardType === CardType.Project || props.cardType === CardType.CommercialProject;
  const withLogoPreview = Boolean(config.universalCardWithLogo && props.logoPath);

  let tags = null;
  if (isProject) {
    tags = <ProjectTag hasDiscount={hasDiscount} isPromoted={isPromoted} />;
  }
  else {
    tags = (
      <ListingTags
        hasPriceDrop={isPriceDropped(eventsHistory)}
        lastUpdatedDate={lastUpdatedDate}
        createdDate={date}
        dealType={dealType}
        id={id}
      />
    );
  }

  return (
    <div>
      <CardImageWithTagsWrapper>
        {withLogoPreview ? (
          <ThumbnailLogoWrapper className="thumbnail-logo" small={small}>
            <LogoLink size={40} logoPath={logoPath} cardType={cardType} id={logoSourceId} />
          </ThumbnailLogoWrapper>
        ) : null}
        <TagsWrapper small={small}>
          {tags}
        </TagsWrapper>
        <PropertyImage layout={small ? 'vertical' : 'horizontal'} thumbnails={thumbnails} />
      </CardImageWithTagsWrapper>
    </div>
  );
};

export default CardImageWithTags;
